import ApplicationController from './application_controller'
import DataTable from 'datatables.net-dt'
import 'datatables.net-buttons/js/dataTables.buttons'
import 'datatables.net-buttons/js/buttons.colVis'

var dt_id = 0

export default class extends ApplicationController {
  static values = {
    config: String,
    defaultSortColumnIndex: Number,
    hiddenColumns: Array
  }

  _table = null

  connect() {
    super.connect()
    this._initTable = this._initTable.bind(this)
    if (typeof DataTable !== "undefined" && DataTable !== null) {
      this._initTable()
    } else {
      this.log("Waiting for load event to initialize table")
      document.addEventListener("turbo:load", this._initTable, false)
    }
  }

  disconnect() {
    super.disconnect()
  }
  _initTable() {
    const tableContent = this.element;
    // HACK -- The way this thing mounts it attempts to construct the table before the table exists
    const thead = Array.from(tableContent.children).find(child => child.tagName.toLowerCase() === 'thead');
    const tr = Array.from(thead.children).find(child => child.tagName.toLowerCase() === 'tr');
    const largestHiddenColumn = Math.max(...this.hiddenColumnsValue);
    if (!this._table && thead && tr && tr.children.length >= largestHiddenColumn) {
      // END OF HACK -- don't actually try to mount the table until the table exists
      this._table = new DataTable(tableContent, {
        colReorder: true,
        autoWidth: false,
        order: [[this.defaultSortColumnIndex, 'desc']],
        layout: {
          topStart: {
            buttons: [
              {
                  extend: 'collection',
                  text: 'Export',
                  buttons: ['csv', 'copy']
              },
              {
                extend: 'colvis',
                text: 'Show/Hide Columns'
              },
              'pageLength'
            ]
          }
        },
        columnDefs: [
          {
            targets: this.hiddenColumnsValue,
            visible: false
          }
        ]
      });
    }
  }
  get table() {
    return this._table
  }
  get defaultSortColumnIndex() {
    return this._defaultSortColumnIndex = this._defaultSortColumnIndex || this.defaultSortColumnIndexValue || 4
  }
}
