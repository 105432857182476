import ApplicationController from './application_controller'

// Simple controller for toggling a CSS class based on clicks. Makes no assumptions about starting state,
// so whatever the markup has will be toggled on click.
export default class extends ApplicationController {
  static values = {
    cssClass: String
  }

  toggle() {
    this.element.classList.toggle(this.cssClassValue)
  }
}
