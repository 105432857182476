import ApplicationController from './application_controller'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getRelativePosition } from 'chart.js/helpers';

export default class extends ApplicationController {
  static values = {
    labels: String,
    chartData: String,
    dateCount: Number,
    teamAndAvgCount: Number,
    showLabelInChart: Boolean,
    maxNumber: Number,
    minNumber: Number
  }

  connect() {
    super.connect()
    console.log('ChartMatrixController connected')
    this._initChart = this._initChart.bind(this)
    if (typeof Chart !== "undefined" && Chart !== null) {
      this._initChart()
    } else {
      this.log("Waiting for load event to initialize chart")
      document.addEventListener("turbo:load", this._initChart, false)
    }
  }

  disconnect() {
    document.removeEventListener("turbo:load", this._initChart)
    this._chart.destroy()
    super.disconnect()
  }

  _initChart() {
    const matrixContext = this.element.querySelector('canvas').getContext('2d');
    console.log('chartLabelsValue:', this.labels);
    console.log('chartDataValue:', this.chartData);
    const chartDates = this.labels;
    const chartData = this.chartData;
    const showLabelInChart = this.showLabelInChart;
    const maxNumber = this.maxNumber;
    const minNumber = this.minNumber;

    const matrixData = {
      label: 'My Matrix',
      datasets: [{
        data: chartData,
        backgroundColor(context) {
          const value = context.dataset.data[context.dataIndex].v;
          var alphaMinNumber = context.dataset.data[context.dataIndex].min_value || minNumber;
          var alphaMaxNumber = context.dataset.data[context.dataIndex].max_value || maxNumber;
          let alpha = (value - alphaMinNumber) / (alphaMaxNumber - alphaMinNumber);
          alpha = Math.max(alpha, 0);
          return `rgba(0, 128, 0, ${alpha})`; // green
        },
        borderColor(context) {
          const value = context.dataset.data[context.dataIndex].v;
            var alphaMinNumber = context.dataset.data[context.dataIndex].min_value || minNumber;
          var alphaMaxNumber = context.dataset.data[context.dataIndex].max_value || maxNumber;
          let alpha = (value - alphaMinNumber) / (alphaMaxNumber - alphaMinNumber);
          alpha = (Math.max(alpha, 0) + 0.1) * 0.8; // Some complex math to keep the border somewhere between 8% and 80% transparency
          return `rgba(0, 0, 0, ${alpha})`; // black
        },
        borderWidth: 1,
        width: ({ chart }) => (chart.chartArea || {}).width / this.dateCount - 1,
        height: ({ chart }) => (chart.chartArea || {}).height / this.teamAndAvgCount - 1
      }]
    };

    const matrixConfig = {
      type: 'matrix',
      data: matrixData,
      plugins: [ChartDataLabels],
      options: {
        onClick: (e) => {
            const chart = this._chart
            const points = chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);
            if (points.length) {
                const firstPoint = points[0];
                const label = this._chartData[firstPoint.index].y;
                const queryParams = this._chartData[firstPoint.index].query_params;
                const url = new URL(window.location.origin + '/activity');
                const id = new URLSearchParams(window.location.search).get('id');
                if (id) {
                  url.searchParams.append('id', id);
                }
                Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));
                const canvas = this.element.querySelector('canvas');
                canvas.style.display = 'none';
                canvas.parentElement.classList.add('loading');
                window.location.href = url.toString();
            } else {
              console.warn('No points clicked');
            }
        },
        onHover: (e) => {
          const chart = this._chart;
          const points = chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);
          e.native.target.style.cursor = points.length ? 'pointer' : 'default';
        },
        animation: false,
        plugins: {
          legend: false,
          tooltip: {
            displayColors: false,
            callbacks: {
              label(context) {
                const v = context.dataset.data[context.dataIndex];
                return [v.y + ': ' + v.v];
              }
            }
          },
          datalabels: {
            color: (context) => {
              const value = context.dataset.data[context.dataIndex].v;
              var alphaMinNumber = context.dataset.data[context.dataIndex].min_value || minNumber;
              var alphaMaxNumber = context.dataset.data[context.dataIndex].max_value || maxNumber;
              let alpha = (value - alphaMinNumber) / (alphaMaxNumber - alphaMinNumber);
              return alpha < 0.75 ? 'black' : 'white';
            },
            formatter(value) {
              return showLabelInChart ? value.v : '';
            }
          }
        },
        scales: {
          x: {
            type: 'category',
            labels: chartDates,
            ticks: {
              display: true
            },
            grid: {
              display: false
            }
          },
          y: {
            type: 'category',
            offset: true,
            ticks: {
              display: true
            },
            grid: {
              display: false
            }
          }
        }
      }
    };

    this._chart = new Chart(matrixContext, matrixConfig);
    const loadingDiv = this.element.querySelector(".loading")
    if(loadingDiv) {
      loadingDiv.classList.remove('loading')
    }
  }

  get chart() {
    return this._chart
  }
  get labels() {
    return this._labels = this._labels || JSON.parse(this.labelsValue)
  }
  get chartData() {
    return this._chartData = this._chartData || JSON.parse(this.chartDataValue)
  }
  get dateCount() {
    return this._dateCount = this._dateCount || JSON.parse(this.dateCountValue)
  }
  get teamAndAvgCount() {
    return this._teamAndAvgCount = this._teamAndAvgCount || JSON.parse(this.teamAndAvgCountValue)
  }
  get showLabelInChart() {
    return this._showLabelInChart = this._showLabelInChart || this.showLabelInChartValue
  }
  get maxNumber() {
    return this._maxNumber = this._maxNumber || this.maxNumberValue
  }
  get minNumber() {
    return this._minNumber = this._minNumber || this.minNumberValue
  }
}
